import React, { ReactNode } from 'react'
import { textWithLocale } from '../../../i18n'
import { generateFichaNeumaticoMotoUrl, getNeumaticoMotoSpecs, neumaticoActivo, } from '../utils'
import styles from './accordion.module.scss'
import { ModeloMotoInfo, NeumaticoMotoInfo, NeumaticoMotoProducto, } from '../types'
import Size from '../../../utils/media-queries-setup'

interface ContentProps {
	neumatico: NeumaticoMotoInfo & NeumaticoMotoProducto
	modelo: ModeloMotoInfo
	comprarCta: ReactNode
	requestTyreCta: ReactNode
}

export const AccordionMotoContent = ({
	neumatico,
	modelo,
	comprarCta,
	requestTyreCta,
}: ContentProps) => {
	const isMobile = Size.useMedia({mobile: true, tablet: false, desktop: false})

	const nombreProducto = `${
		neumatico.producto[textWithLocale('nombre_producto')]
	} ${getNeumaticoMotoSpecs(neumatico)}`
	const nombre =
		neumatico.url_publica === 1 ? (
			<a href={generateFichaNeumaticoMotoUrl(neumatico, modelo)}>
				{nombreProducto}
			</a>
		) : (
			nombreProducto
		)

	return (
		<ul key={neumatico.id_site_productos_neumaticos_moto}>
			<li>
				<div className={styles.accordion__item_container}>
					<div
						className={`${styles.accordion__item_element} ${styles.item_element_title_moto}`}>
						<h3>{nombre}</h3>
					</div>
					{!isMobile && <div
						className={`${styles.accordion__item_element} ${styles.item_element_aplicacion_moto}`}>
						{neumatico.aplicacion.toUpperCase()}
					</div>}
					<div
						className={`${styles.accordion__item_element} ${styles.item_element_button_moto}`}>
						{neumaticoActivo(neumatico.producto) ? comprarCta : requestTyreCta}
					</div>
				</div>
			</li>
		</ul>
	)
}
