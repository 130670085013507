const gamaTipoToFolderMap: Record<string, Record<string, string>> = {
  '50% OFF ROAD': {
    TRAIL: 'TRAIL TODOTERRENO',
  },
  CARRETERA: {
    CLASICAS: 'CARRETERA',
    CLASICO: 'CARRETERA',
    TURISMO: 'CARRETERA',
    'GRAN TURISMO': 'CARRETERA',
    RACING: 'CARRETERA',
    SPORT: 'CARRETERA',
    URBANO: 'CARRETERA',
  },
  CICLOMOTOR: {
    'BANDA BLANCA': 'CICLOMOTOR',
    CLASICAS: 'CICLOMOTOR',
    URBANO: 'CICLOMOTOR',
  },
  'CIRCUITO VELOCIDAD': {
    'AGUA LLUVIA': 'CIRCUITO-DE-VELOCIDAD',
    'SLICK HARD': 'CIRCUITO-DE-VELOCIDAD',
    'SLICK MEDIUM': 'CIRCUITO-DE-VELOCIDAD',
    'SLICK SOFT': 'CIRCUITO-DE-VELOCIDAD',
    'SLICK SUPER SOFT': 'CIRCUITO-DE-VELOCIDAD',
    'SLICK TANDAS': 'CIRCUITO-DE-VELOCIDAD',
    'SLICK ULTRA SOFT': 'CIRCUITO-DE-VELOCIDAD',
    CLASICAS: 'CLASICAS-COMPETICION',
    RACING: 'CLASICAS-COMPETICION',
  },
  CLASICAS: {
    CUSTOM: 'CLASICAS',
  },
  CUSTOM: {
    CLASICAS: 'CLASICAS',
    'BANDA BLANCA': 'CUSTOM',
    'BANDA BLANCA ANCHA': 'CUSTOM',
    'CRUISER / TOURING': 'CUSTOM',
    'FLANCO STANDARD NEGRO': 'CUSTOM',
    'HARLEY D.': 'CUSTOM',
    'HARLEY D. BANDA BLANCA': 'CUSTOM',
    'HARLEY D. FLANCO NEGRO': 'CUSTOM',
    'LETRAS BLANCAS CON RELIEVE': 'CUSTOM',
    'LETRAS BLANCAS RELIEVE': 'CUSTOM',
    TOURING: 'CUSTOM',
  },
  ENDURO: {
    HARD: 'ENDURO',
    MEDIUM: 'ENDURO',
    SOFT: 'ENDURO',
  },
  'ENDURO XTREM': {
    'EXTRA SOFT': 'ENDURO-EXTREME',
    MEDIUM: 'ENDURO-EXTREME',
    SOFT: 'ENDURO-EXTREME',
    'SUPER SOFT': 'ENDURO-EXTREME',
  },
  'ENDURO-CROSS': {
    'MIXTO-DURO': 'MOTOCROSS',
  },
  MEDIUM: {
    ENDURO: 'ENDURO',
    SUPERSPORT: 'SUPERSPORT',
  },
  MOTOCROSS: {
    ARENA: 'MOTOCROSS',
    BARRO: 'MOTOCROSS',
    DURO: 'MOTOCROSS',
    'MIXTO BLANDO': 'MOTOCROSS',
    'MIXTO DURO': 'MOTOCROSS',
  },
  'OFF ROAD OCIO': {
    CLASICAS: 'MOTOCROSS',
    'MIXTO BLANDO': 'MOTOCROSS',
    'MIXTO DURO': 'MOTOCROSS',
  },
  'RALLY RAID': {
    'RALLY RAID': 'RALLY-RAID',
  },
  SCOOTER: {
    'BANDA BLANCA': 'SCOOTER',
    CLASICAS: 'SCOOTER',
    INVIERNO: 'SCOOTER',
    'OFF ROAD': 'SCOOTER',
    RACING: 'SCOOTER',
    SPORT: 'SCOOTER',
    URBANO: 'SCOOTER',
  },
  'SCOOTER COMPETICION': {
    'AGUA LLUVIA': 'SCOOTER-COMPETICION',
    'SEMI-SLICK MEDIUM': 'SCOOTER-COMPETICION',
    'SEMI-SLICK EXTRA SOFT': 'SCOOTER-COMPETICION',
    'SEMI-SLICK HARD': 'SCOOTER-COMPETICION',
    'SEMI-SLICK SOFT': 'SCOOTER-COMPETICION',
    'SEMI-SLICK SUPER SOFT': 'SCOOTER-COMPETICION',
    'SLICK MEDIUM': 'SCOOTER-COMPETICION',
    'SLICK SOFT': 'SCOOTER-COMPETICION',
  },
  SIDECAR: {
    SIDECAR: 'SIDECAR',
  },
  SPEEDWAY: {
    SPEEDWAY: 'SPEEDWAY',
  },
  'SUPER SOFT': {
    ENDURO: 'ENDURO-EXTREME',
    XTREM: 'ENDURO-EXTREME',
  },
  SUPERMOTARD: {
    'LLUVIA - DIRT TRACK': 'SUPERMOTARD',
    'RAIN-D TRACK': 'SUPERMOTARD',
    'SLICK HARD': 'SUPERMOTARD',
    'SLICK MEDIUM': 'SUPERMOTARD',
    'SLICK SOFT': 'SUPERMOTARD',
    'SLICK SUPER SOFT': 'SUPERMOTARD',
  },
  SUPERSPORT: {
    HARD: 'SUPERSPORT',
    MEDIUM: 'SUPERSPORT',
    SOFT: 'SUPERSPORT',
    'SUPER SOFT': 'SUPERSPORT',
  },
  TRAIL: {
    ASFALTICO: 'TRAIL-MIXTO',
    '25% OFF ROAD': 'TRAIL-TODOTERRENO',
    '50% OFF ROAD': 'TRAIL-TODOTERRENO',
    '75% OFF ROAD': 'TRAIL-TODOTERRENO',
  },
  TRIAL: {
    CLASICAS: 'TRIAL',
    COMPETICION: 'TRIAL',
    OCIO: 'TRIAL',
    SOFT: 'TRIAL',
    'SUPER SOFT': 'TRIAL',
  },
}

export const getMotoIconPath = async (
  gama: string,
  tipo: string,
  aplicacion: string
) => {
  const folder = gamaTipoToFolderMap[gama]?.[tipo]

  if (!folder) {
    console.warn(`No folder found for gama: ${gama}, tipo: ${tipo}`)
    return null
  }

  const aplicacionToIconMap: Record<string, string> = {
    delantero: 'DELANTERO.svg',
    trasero: 'TRASERO.svg',
    'delantero/trasero': 'AMBOS.svg',
    general: 'GENERAL.svg',
  }

  const iconFile =
    aplicacionToIconMap[aplicacion.toLowerCase()] || 'GENERAL.svg'

  try {
    const sanitizedFolder = folder.replace(/-/g, '')
    const iconPath = `./assets/Icons-moto/${encodeURIComponent(
      folder
    )}/${encodeURIComponent(sanitizedFolder)}-${encodeURIComponent(iconFile)}`

    const IconComponent = (await import(`${iconPath}`)).default
    return {
      name: iconFile,
      icon: IconComponent,
    }
  } catch (error) {
    console.warn(`Icon not found: ${iconFile}`)
    return null
  }
}
