import { Tag } from '../../../../shared/catalogo/producto/ficha/Tags/Tags'
import { t } from '../../../i18n'
import { NeumaticoMotoInfo } from '../types'

export const generateModeloMotoTags = (neumatico: NeumaticoMotoInfo): Tag[] => {
  return [
    neumatico.gama && {
      label: neumatico.gama,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_moto_gama', {
        gama: neumatico.gama,
      }),
    },
    neumatico.tipo && {
      label: neumatico.tipo,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_moto_tipo', {
        tipo: neumatico.tipo,
      }),
    },
  ].filter(Boolean)
}
