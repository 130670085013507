import styles from './accordion.module.scss'
import React, { useMemo, useState } from 'react'
import { groupNeumaticos } from '../utils'
import { AccordionMotoContent } from './AccordionMotoContent'
import AccordionHeader from './AccordionHeader'
import Accordion from './Accordion'
import { t } from '../../../i18n'
import { RequestTyreMotoData } from '../../RequestTyre/RequestTyreMoto'
import RequestMotoTyreCTA from '../Ctas/RequestMotoTyreCTA'
import {
  ModeloMotoInfo,
  NeumaticoMotoInfo,
  NeumaticoMotoProducto,
  NeumaticosMoto,
} from '../types'
import WhiteBoxContainer from '../../../../shared/components/WhiteBoxContainer'
import ComprarNeumaticoAccordionCTA from '../Ctas/ComprarNeumaticoAccordionCTA'
import RequestTyre from '../../RequestTyre/RequestTyre'
import Modal from '../../../../shared/components/Modal/Modal'
import logic from '../../../logic'

const headerItems = [
  {
    title: 'landing_neumaticos-moto.list_header.item_one',
    className: styles.header_title,
  },
  {
    title: 'landing_neumaticos.titulos_caracteristicas_tecnicas.aplicacion',
    className: styles.header_aplicacion_moto,
  },
  {
    title: 'landing_neumaticos-moto.list_header.item_four',
    className: styles.header_button_moto,
  },
]

interface Props {
  modelo: ModeloMotoInfo
  neumaticos: NeumaticosMoto
}

export default function NeumaticosMotoAccordion({ modelo, neumaticos }: Props) {
  const [showModal, setShowModal] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreMotoData | null
  )
  const [successMessage, setSuccessMessage] = useState(false)

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const grouped = useMemo(
    () => groupNeumaticos(neumaticos, 'llanta'),
    [neumaticos]
  )

  const doContent = (neumatico: NeumaticoMotoInfo & NeumaticoMotoProducto) => {
    return (
      <AccordionMotoContent
        key={`accordion_moto_${neumatico.producto.id_navision}`}
        neumatico={neumatico}
        modelo={modelo}
        comprarCta={
          <ComprarNeumaticoAccordionCTA
            neumaticoInfo={neumatico}
            marcaInfo={modelo.marca}
            buttonClass={'accordion_button'}
          />
        }
        requestTyreCta={
          <RequestMotoTyreCTA
            neumatico={neumatico}
            modelo={modelo}
            openModal={setShowModal}
            selectNeumatico={setSelectedNeumatico}
            flexDirection={'row'}
          />
        }
      />
    )
  }
  return (
    <React.Fragment>
      <WhiteBoxContainer className="fullWidthContent">
        <Accordion
          grouped={grouped}
          header={<AccordionHeader items={headerItems} />}
          contentRenderFnc={doContent}
          itemHeader={'landing_neumaticos.list_item_header.llanta'}
        />
        <Modal
          title={t('landing_neumaticos.neumatico_item.disponibilidad')}
          closeModal={closeModal}
          showModal={showModal}>
          <RequestTyre
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            selectedNeumatico={selectedNeumatico}
            closeModal={closeModal}
            requestAvailability={({
              email,
              selectedNeumatico,
              device,
              precio,
              idioma,
              cantidad = 1,
            }) =>
              logic.requestNeumaticoMotoAvailability(
                email,
                selectedNeumatico.marca,
                selectedNeumatico.modelo,
                selectedNeumatico.id_site_modelo_neumatico_moto,
                selectedNeumatico.id_site_producto,
                selectedNeumatico.tyreSpecs,
                device,
                precio,
                idioma,
                cantidad
              )
            }
          />
        </Modal>
      </WhiteBoxContainer>
    </React.Fragment>
  )
}
