import React from 'react'
import { t, textWithLocale } from '../../../i18n'
import Button from '../../buttons/Button'
import { RequestTyreMotoData } from '../../RequestTyre/RequestTyreMoto'
import {
  ModeloMotoInfo,
  NeumaticoMotoInfo,
  NeumaticoMotoProducto,
} from '../types'
import styles from '../../../../shared/catalogo/producto/ficha/cta/cta.module.scss'

interface Props {
  modelo: ModeloMotoInfo
  neumatico: NeumaticoMotoInfo & NeumaticoMotoProducto
  selectNeumatico: (selected: RequestTyreMotoData) => void
  openModal: (open: boolean) => void
  flexDirection?: 'column' | 'row'
}

const RequestMotoTyreCTA: React.FC<Props> = ({
  modelo,
  neumatico,
  selectNeumatico,
  openModal,
  flexDirection = 'column',
}) => {
  const requestedNeumatico: RequestTyreMotoData = {
    marca: modelo.marca.nombre,
    modelo: modelo.modelo,
    id_site_modelo_neumatico_moto: modelo.id_site_modelo_neumatico_moto,
    id_site_producto: neumatico.id_site_productos_neumaticos_moto,
    tyreSpecs: {
      ancho: neumatico.ancho,
      alto: neumatico.alto,
      llanta: neumatico.llanta,
      serie: neumatico.serie,
      aplicacion: neumatico.aplicacion,
      normativa: neumatico.normativa,
      radial: neumatico.radial,
    },
    descripcion: neumatico.producto[textWithLocale('descripcion')],
    precio: neumatico.producto.precio,
  }

  const onClickRequest = () => {
    selectNeumatico(requestedNeumatico)
    openModal(true)
  }
  return (
    <div
      className={`${styles.wrapper} ${
        flexDirection === 'column' ? styles.wrapper_col : styles.wrapper_row
      }`}>
      <div className={`${styles.price_header} ${styles.price_header_model}`}>
        <span className={styles.no_disponible}>
          {t('catalogo.producto.ficha.no_disponible')}
        </span>
      </div>
      <Button buttonClass={'no_disponible_button'} onClick={onClickRequest}>
        {t('catalogo.producto.ficha.recibir_alerta')}
      </Button>
    </div>
  )
}

export default RequestMotoTyreCTA
