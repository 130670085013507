import React, { useEffect, useRef, useState } from 'react'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../components/Layout'
import { t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import { makeNeumaticosSchema } from '../../utils/schema'
import {
  getModeloImagenes,
  getModeloMarca,
  selectLowestMontajeService,
  selectLowestPrice,
} from '../../components/FichasNeumaticos/utils'
import SeleccionarMedidasCTA from '../../components/FichasNeumaticos/Ctas/SeleccionarMedidasCTA'
import NeumaticosMotoAccordion from '../../components/FichasNeumaticos/Accordion/NeumaticosMotoAccordion'
import SectionWithHeading from '../../../shared/components/SectionWithHeading/SectionWithHeading'
import CatalogoFicha from '../../../shared/catalogo/producto/ficha/CatalogoFicha'
import styles from './fichas.module.scss'
import Filters from '../../components/landingNeumaticos/Filters'
import logic from '../../logic'
import { getMotoIconPath } from '../../components/FichasNeumaticos/utilsIconoMoto'
import { generateModeloMotoTags } from '../../components/FichasNeumaticos/Tags/generateModeloMotoTags'
import { removeAccents } from '../../utils/removeAccents'
import useUpdatePromocion from '../../helpers/customHooks/useUpdatePromocion'
import { getFetchFunction } from '../../../shared/catalogo/producto/ficha/promocion/getFetchFunction'
import { CatalogoProductInfo } from '../../../shared/catalogo/producto/ficha/CatalogoProductInfo'

const deepIsEqual = (first: unknown, second: unknown) => {
  if (first === second) return true

  // Try a quick compare by seeing if the length of properties are the same
  const firstProps = Object.getOwnPropertyNames(first)
  const secondProps = Object.getOwnPropertyNames(second)

  if (firstProps.length !== secondProps.length) return false

  for (let i = 0; i < firstProps.length; i++) {
    const prop = firstProps[i]
    switch (typeof first[prop]) {
      // If it is an object, decend for deep compare
      case 'object':
        if (!deepIsEqual(first[prop], second[prop])) return false
        break
      case 'number':
        if (isNaN(first[prop]) && isNaN(second[prop])) break
        return false
      default:
        if (first[prop] !== second[prop]) return false
    }
  }
  return true
}
const filterData = (
  arr: Queries.FichaModelosMotoPageQuery['neumaticosInfo']['nodes'],
  newFilters: Record<string, unknown>
) => {
  return arr.filter((item) => {
    const obj = {}
    Object.keys(item).forEach((k) => {
      if (Object.prototype.hasOwnProperty.call(newFilters, k)) {
        obj[k] = item[k]
      }
    })
    return deepIsEqual(obj, newFilters)
  })
}
const resetInitialOptions = () => ({
  ancho: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
  alto: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
  llanta: [{ label: t('landing_neumaticos.drop_down_all'), value: '' }],
})

export default function FichaModelosMotoPage({
  data: {
    modeloInfo,
    neumaticosInfo: { nodes: neumaticos },
    anchoOptions: { distinct: anchoDropDownOptions },
    altoOptions: { distinct: altoDropDownOptions },
    llantaOptions: { distinct: llantaDropDownOptions },
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_es_mobile,
    banners_ca_mobile,
  },
}: PageProps<Queries.FichaModelosMotoPageQuery>) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
  }

  const { fichas } = getModeloImagenes(imagenes)

  const medidasRef = useRef<HTMLDivElement>(null)

  const [dropDownOptions, setDropDownOptions] = useState(null)
  const [dropDownSelection, setDropDownSelection] = useState({})
  const [filtrosSelected, setFiltrosSelected] = useState({})
  const [filteredNeumaticos, setFilteredNeumaticos] = useState(neumaticos)

  const getNewDropDownOptions = async (value, label) => {
    const newDropDownSelection = { ...dropDownSelection, [label]: value }
    let query
    if (Object.keys(newDropDownSelection).length === 3) {
      return
    }
    if (!Object.keys(newDropDownSelection).length) {
      query = `${label}=${value}`
    } else {
      query = Object.keys(newDropDownSelection)
        .filter((k) => newDropDownSelection[k] !== '')
        .map((item) => `${item}=${newDropDownSelection[item]}`)
        .join('&')
    }
    query = `${query}&idModelo=${modeloInfo.id_site_modelo_neumatico_moto}`
    const calculatedOptions = await logic.retrieveAllMotoDropDownOptions(query)
    Object.keys(calculatedOptions).forEach((k) => {
      if (Array.isArray(calculatedOptions[k])) {
        return calculatedOptions[k].unshift({
          label: t('landing_neumaticos.drop_down_all'),
          value: '',
        })
      }
    })
    const newOptions = { ...dropDownOptions, ...calculatedOptions }
    setDropDownOptions(newOptions)
  }
  const handleFilterChange = (event) => {
    if (event) {
      const { value, name } = event
      setDropDownSelection((prevState) => {
        return {
          ...prevState,
          [name]: {
            label: value,
            value,
          },
        }
      })
      getNewDropDownOptions(value, name)
      const newFilters = { ...filtrosSelected, [name]: value }
      Object.keys(newFilters).forEach(
        (key) => newFilters[key] === '' && delete newFilters[key]
      )
      setFiltrosSelected(newFilters)
      const filteredData = filterData(neumaticos, newFilters)
      setFilteredNeumaticos(filteredData)
    }
  }

  useEffect(() => {
    const initialOptions = resetInitialOptions()
    const setInitialDropDown = (options, key) => {
      if (options && options.length > 0) {
        initialOptions[key] = [
          { label: t('landing_neumaticos.drop_down_all'), value: '' },
          ...options.map((item) => ({
            label: item,
            value: item,
          })),
        ]
      }
    }

    setInitialDropDown(anchoDropDownOptions, 'ancho')
    setInitialDropDown(altoDropDownOptions, 'alto')
    setInitialDropDown(llantaDropDownOptions, 'llanta')

    setDropDownOptions(initialOptions)

    // Set initial dropDownSelection to first item of each dropdown
    setDropDownSelection({
      ancho:
        filtrosSelected.ancho ||
        (initialOptions && initialOptions.ancho[0]) ||
        '',
      alto:
        filtrosSelected.alto ||
        (initialOptions && initialOptions.alto[0]) ||
        '',
      llanta:
        filtrosSelected.llanta ||
        (initialOptions && initialOptions.llanta[0]) ||
        '',
    })
  }, [anchoDropDownOptions, altoDropDownOptions, llantaDropDownOptions])

  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (!neumaticos || neumaticos.length === 0) {
      return
    }
    const loadIcon = async () => {
      const gama = removeAccents(neumaticos[0].gama)
      const tipo = removeAccents(neumaticos[0].tipo)
      const fetchedIcon = await getMotoIconPath(gama, tipo, 'modelos')
      setIcon(fetchedIcon)
    }

    loadIcon()
  }, [neumaticos])

  const catalogoProductInfo: CatalogoProductInfo = {
    ...modeloInfo,
    estaciones: icon ? [icon] : [],
    nombre_producto: `${modeloInfo.marca.nombre} ${modeloInfo.modelo}`,
  }

  const updatedPromotion = useUpdatePromocion({
    id_site_marca_neumatico: modeloInfo.id_site_marca_neumatico,
    id_site_modelo_neumatico: modeloInfo.id_site_modelo_neumatico_moto,
    llanta: undefined,
    runflat: undefined,
    allseason: undefined,
    invierno: undefined,
    postCode: null,
    categoria_producto: 'moto',
    fetchFunction: getFetchFunction('neumatico'),
  })

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos-moto.marca', { marca: marca.toLowerCase() }),
        },
        {
          display: modelo,
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: getModeloMarca(modeloInfo),
              image: fichas.length && fichas[0].url,
              description: modeloInfo[textWithLocale('recomendado_para')],
              brand: marca,
              price: selectLowestPrice(
                neumaticos.map((neum) => neum.producto)
              ).toString(),
            })
          ),
        }}
      />
      <CatalogoFicha
        catalogoProductInfo={catalogoProductInfo}
        specifications={[
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.marca'
            ),
            value: `${modeloInfo.marca.nombre}`,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.modelo'
            ),
            value: modeloInfo.modelo,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.gama'
            ),
            value: neumaticos.length > 0 ? neumaticos[0].gama : null,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.tipo'
            ),
            value: neumaticos.length > 0 ? neumaticos[0].tipo : null,
          },
        ]}
        banners={banners}
        cta={
          <SeleccionarMedidasCTA
            modelo={modeloInfo}
            precio={selectLowestPrice(neumaticos.map((neum) => neum.producto))}
            reference={medidasRef}
            servicio_montaje={selectLowestMontajeService(
              neumaticos.map((neum) => neum.producto)
            )}
            bannerPromocion={updatedPromotion}
          />
        }
        tags={
          neumaticos.length > 0 ? generateModeloMotoTags(neumaticos[0]) : []
        }>
        <SectionWithHeading
          title={`${neumaticos.length} ${t(
            'landing_neumaticos.info.medidas_encontradas'
          )}`}
          afterTitle={`${marca} ${modelo}`.toUpperCase()}
          ref={medidasRef}>
          <div className={styles.ficha_neumatico__filters_content}>
            {dropDownSelection && (
              <Filters
                currentSelection={dropDownSelection}
                onChange={handleFilterChange}
                options={dropDownOptions}
              />
            )}
          </div>
          <NeumaticosMotoAccordion
            neumaticos={filteredNeumaticos}
            modelo={modeloInfo}
          />
        </SectionWithHeading>
      </CatalogoFicha>
    </Layout>
  )
}

export const pageQuery = graphql`
  query FichaModelosMotoPage(
    $idModelo: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_desktop: String
    $banners_pt_mobile: String
  ) {
    modeloInfo: modelosMoto(id_site_modelo_neumatico_moto: { eq: $idModelo }) {
      ...modeloMotoInfo
      ...modeloMotoMarca
      ...modeloMotoImagen
    }
    neumaticosInfo: allNeumaticosMoto(
      filter: { id_site_modelo_neumatico_moto: { eq: $idModelo } }
    ) {
      nodes {
        ...neumaticoMotoInfo
        ...productoMotoInfo
      }
    }

    anchoOptions: allNeumaticosMoto(
      filter: { id_site_modelo_neumatico_moto: { eq: $idModelo } }
    ) {
      distinct(field: ancho)
    }
    altoOptions: allNeumaticosMoto(
      filter: { id_site_modelo_neumatico_moto: { eq: $idModelo } }
    ) {
      distinct(field: alto)
    }
    llantaOptions: allNeumaticosMoto(
      filter: { id_site_modelo_neumatico_moto: { eq: $idModelo } }
    ) {
      distinct(field: llanta)
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
